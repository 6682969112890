<template>
    <styled-interface hide-toolbar>
        <styled-card
            class="mt-5 mb-5"
            :width="1055"
            hide-toggle
            centered>
            <template #heading>
                Install EventFlowPLUS
            </template>
            <loader v-if="loading" />
            <div
                v-else-if="error"
                class="error">
                {{ error }}
            </div>
            <div
                v-else
                class="holder">
                <p>
                    Use the script below to activate BuyerBridge's ability to
                    track calls, retarget traffic and report on user behavior.
                    <br>
                    You can copy the script directly below or
                    <a
                        href="#"
                        @click.prevent="onDownloadEventFlowCode">
                        click here to download a zip file
                    </a>
                </p>
                <h3 class="mt-5 mb-2">
                    EventFlowPLUS Tag Installation
                </h3>
                <p class="mb-4">
                    Install this tag directly on every page of {{ domain }} as high in the <b>&lt;head&gt;</b> as possible.<br>
                    Because it uses first-party cookies it <b>cannot</b> be added through Google Tag Manager.
                </p>
                <copy-text-area
                    custom-padding="35"
                    download-filename="event-flow-plus-tag.txt"
                    class="mb-4">
                    <pre>{{ mainScript }}</pre>
                </copy-text-area>
            </div>
        </styled-card>
    </styled-interface>
</template>

<script>
import axios from 'axios';
import Loader from '@/components/globals/Loader';
import StyledCard from '@/components/globals/StyledCard';
import CopyTextArea from '@/components/globals/CopyTextArea';
import StyledInterface from '@/components/globals/StyledInterface';
import { downloadEventFlowTagCode, getEventFlowTagScript } from '@/helpers/eventFlowCode';

export default {
    title: 'EventFlowPLUS Tag Installation',
    components: {
        Loader,
        StyledCard,
        CopyTextArea,
        StyledInterface,
    },
    data() {
        return {
            uuid: null,
            domain: '',
            loading: false
        };
    },
    computed: {
        mainScript() {
            return getEventFlowTagScript(this.uuid, this.domain);
        },
    },
    created() {
        this.uuid = this.$route.params.uuid;
        this.getDomain();
    },
    methods: {
        onDownloadEventFlowCode() {
            downloadEventFlowTagCode(this.uuid, this.domain);
        },
        async getDomain() {
            this.loading = true;
            this.error = '';
            try {
                const response = await axios.get(`https://public-api.buyerbridge.io/v1/accounts/${this.uuid}`);
                this.domain = response.data?.data?.conversion_endpoints[0]?.domain ?? '';
                if (!this.domain) { this.error = 'Error getting domain' }
            } catch(e) {
                this.error = e;
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.holder {
    padding: 30px 45px;
}
</style>
